/* TODO responsive for phones */

.textLimiter{
    @media screen and (max-width: 768px) {
        width: 100px;
    }
}
.textToLimit{
    width: 300px;
    color: rgba(255,255,255,0.8);
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}