.container{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    height: 70vh;
    text-align: center;
}
.form h2{
    text-align: center;
}
.newsLBtn{
    background: linear-gradient(90deg, #EB00FF, #009FFF);
    border-radius: 10%;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
}
.newsLBtn:hover{
    background: linear-gradient(90deg, #009FFF, #EB00FF );;
}
.emailField{
    width: 40%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin: 10px;
    font-size: 1.5rem;
    color: #000;
}
.form-group{
    color: #000;
}
.divider{
    background: linear-gradient(90deg, #EB00FF, #009FFF);

}

.emailIcon{
    width: 300px;
    height: 300px;
    @media screen and (max-width: 768px) {
        width: 150px;
        height: 150px;
    }
}

.parForm{
    font-size: 1.5rem;
}
