.gradApp-circle-pink{
    position: absolute;
    z-index: -1;
    top: -10;
    left: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #EB00FF;
    filter: blur(50px);
    -webkit-filter: blur(50px);
}
.gradApp-circle-blue{
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #009FFF;
    filter: blur(50px);
    -webkit-filter: blur(50px);
}
.imgPres{
    height: 400px;
    position: relative;
    z-index: 1;
}
.imageGradient{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Media queries */
@media screen and (min-width: 768px) {
    .gradApp-circle-pink{
        width: 200px;
        height: 200px;
        filter: blur(100px);
        -webkit-filter: blur(100px);
    }
    .gradApp-circle-blue{
        width: 200px;
        height: 200px;
        filter: blur(100px);
        -webkit-filter: blur(100px);
    }
    .imgPres{
        height: 700px;
    }
}