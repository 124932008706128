
.paragraph{
    font-size: 1.8rem;
    text-align: left;
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      font-size: 1.6rem;
    }
}
.logoImage{
    width: 300px;
    height: 300px;
    margin-left: 10%;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
        width: 100px;
        height: 100px;
    }
}

.imStack{
    direction:row;
    @media screen and (max-width: 768px) {
        direction: column;
    }
}

.intro{
    width: 60%;
    @media screen and (max-width: 768px) {
        width: 90%;
        
    }
}

.gradApp-circle-pinkintro{
    position: absolute;
    z-index: -1;
    bottom: -50%;
    left: 40%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #EB00FF;
    filter: blur(150px);
    -webkit-filter: blur(150px);
}
.gradApp-circle-blueintro{
    position: absolute;
    z-index: -1;
    bottom: -50%;
    right: 5;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #009FFF;
    filter: blur(150px);
    -webkit-filter: blur(150px);
}
.divider{
    height: 10px;
    width: 100%;
    position: relative;
    background: linear-gradient(90deg, #EB00FF, #009FFF);
    bottom:0;
}
/* Media queries */
@media screen and (min-width: 768px) {
    .gradApp-circle-pinkintro{
        width: 200px;
        height: 200px;
        filter: blur(150px);
        -webkit-filter: blur(150px);
    }
    .gradApp-circle-blueintro{
        width: 200px;
        height: 200px;
        filter: blur(150px);
        -webkit-filter: blur(150px);
    }
    
}