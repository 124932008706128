.catchy-phrase-container {
    position: relative;
    height: 100vh;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      height: 100vh;
      
    }
  }
  .spacer{
    height: 9%;
  }
  .circle-container-pink {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
  }
  .row{
    flex-direction: row;
    width: 100%;
  }
  .logo{
    user-select: none;
    pointer-events: none;
    position: relative;
    left: 0 auto;
    right: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .circle-pink {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #EB00FF;
    filter: blur(100px);
    -webkit-filter: blur(100px);
  }
  
  .circle-container-blue {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;
  }

  .circle-blue {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #009FFF;
    filter: blur(100px);
    -webkit-filter: blur(100px);
  }
  
  .content {
    text-align: center;
    align-items: center;
  }
  
  .content img {
    margin-bottom: 40px;
  }
  
  .content h1 {
    font-size: 70px;
    font-family: 'Ubuntu';
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .content h2 {
    font-size: 50px;
    font-family: 'Ubuntu';
    font-weight: normal;
    opacity: 0.6;
    margin-top: 0;
    margin-bottom: 80px;
  }

  .button-container{
    height: 50px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    bottom: 0 auto;
  }
  .search-button {
    /* margin-top: 80px; */
    background: linear-gradient(90deg, #ea00ff82, #009dff82);
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 0;
    right: 0;
    width: 70%;
    height: 70px;
    margin: 0 auto;
    top: 0 auto;
    bottom: 0 auto;
    
  /* Media queries for responsiveness */
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      font-size: 17px;
      padding: 8px 16px;
    }

    @media screen and (max-width: 480px) {
      /* Adjust styles for even smaller screens */
      font-size: 9px;
      padding: 6px 12px;
    }

  }
  
  
  .sIcon{
    position: absolute;
    margin-left: -80%;
    top: 0 auto;
    bottom: 0 auto;
    width: 50%;
    height: 50%;
  }

  .TypeAnim{
    position: absolute;
    font-size: 2em;
    font-family: 'Ubuntu';
    left: 20%;
    top: 0 auto;
    bottom: 0 auto;
    display: flex;
    align-items: center;
  }
  .content h3{
    font-family: 'Ubuntu';
    font-size: 40px;
  }
  .appRow{
    flex-direction: row;
  }

  .AppButton{
    background: #000;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 0;
    right: 0;
    height: 70px;
    margin: 0 auto;
    top: 0 auto;
    bottom: 0 auto;
  }

  .Badge{
    width: 160px;
    height: 70px;
  }

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .content h1{
    font-size: 30px;
  }
  .content h2{
    font-size: 20px;
  }
  .search-button{
    width: 87%;
  }
}
