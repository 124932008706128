
.appPresPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.title{
    position: relative;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.001rem;
}

.textBox{
    z-index: 2;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50%;
    left: 0%;
}
.imgP{
    height: 400px;
    position: relative;
    object-fit: cover;
    top:91%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Media queries for responsiveness */
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
        
    }
}
.appPresentation{
    
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.appPresentationT{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
}
.listItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    width: 100%;
    margin: 0 0 0 0;
    color: #fff;
}
.appPresentation h2{
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      font-size: 1.8rem;
        
    }
}
/* Media queries */
@media screen and (min-width: 768px) {
    .appImage {
        flex-direction: row;
    }

    .appImageContainer {
        width: 100%; /* Make the image take up 50% of the width */
    }
    .appPresPage {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .title {
        top: 50%;  
    }
    .textBox {
        width: 50%;
        left: 0%;
    }
    .imgP{
        height: 700px;
        top:96%;
        
    }
}