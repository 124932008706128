
.paragraph{
    font-size: 1.8rem;
    text-align: left;
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      font-size: 1.6rem;
    }
}
.divider{
    height: 10px;
    width: 100%;
    position: relative;
    background: linear-gradient(90deg, rgba(255, 0, 153, 0.4), rgba( 0, 106, 255, 0.6));
    bottom:0;
}

.who-circle-pinkintro{
    position: absolute;
    z-index: -1;
    bottom: -10%;
    left: 0%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: rgba(255,0,153,1);
    filter: blur(150px);
    -webkit-filter: blur(150px);
}
.who-circle-blueintro{
    position: absolute;
    z-index: -1;
    top: 10%;
    right: 5%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: rgba(0,0,255,1);
    filter: blur(150px);
    -webkit-filter: blur(150px);
}
/* Media queries */
@media screen and (min-width: 768px) {
    .gradApp-circle-pinkintro{
        width: 200px;
        height: 200px;
        filter: blur(150px);
        -webkit-filter: blur(150px);
    }
    .gradApp-circle-blueintro{
        width: 200px;
        height: 200px;
        filter: blur(150px);
        -webkit-filter: blur(150px);
    }
    
}