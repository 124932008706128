.features-row{
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 40%;
    text-align: center;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      width: 80%;
      
    }
}

.feature-description{
    font-size: 1.5rem;
    text-align: left;
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      font-size: 1.2rem;
    }
}

.feature h2{
    font-size: 2rem;
    @media screen and (max-width: 768px) {
      /* Adjust styles for smaller screens */
      font-size: 1.5rem;
    }
}

.Icon{
    width: 50px;
    height: 50px;
}
.textb{
    width: 100%;
}